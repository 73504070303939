<template>
    <div>
        <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'" class="d-flex align-center">
                                <div>
                                    <h5>Elo ranking points</h5>
                                    <div class="caption">
                                        <a href="https://docs.google.com/spreadsheets/d/1qMFKYJedRQJW0OaokjPuxGLaiKNvLybrBSQdvxSLr2E/edit?gid=410666512#gid=410666512"
                                            target="_blank" rel="noopener noreferrer"
                                            style="display: block; width: 100%; height: 100%;">Courtesy of Gavin
                                            Reilly</a>
                                    </div>
                                </div>
                            </v-col>
                            <v-spacer />
                            <!-- <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                              <div>
                                  <v-combobox
                                      :items="getWinningTeamsHurling"
                                      v-model="overallTeamSelections"
                                      @change="overallTeamSelectionsChanged"
                                      multiple
                                      placeholder="All"
                                      clearable
                                      label="Teams"
                                      item-text="name" 
                                      return-object
                                      outlined
                                      hide-details
                                      hide-no-data
                                  >
                                  <template #selection="{ item }">
                                      <span v-if="overallTeamSelections.length === 1">{{ item.name }}</span>
                                      <span v-else-if="item.name == overallTeamSelections[0].name">{{ overallTeamSelections.length }} teams</span>
                                  </template>
</v-combobox>
</div>
</v-col>
<v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
    <div>
        <v-combobox :items="overallWinnerYearFromFilters" v-model="overallTotalsYearFromFilter"
            @change="overallTotalsYearFromFilterChanged" label="Year from" outlined hide-details
            hide-no-data></v-combobox>
    </div>
</v-col>
<v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
    <div>
        <v-combobox :items="overallWinnerYearToFilters" v-model="overallTotalsYearToFilter"
            @change="overallTotalsYearToFilterChanged" label="Year to" outlined hide-details hide-no-data></v-combobox>
    </div>
</v-col> -->
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <div id="overall-table">
                            <v-data-table :headers="activeHeaders" :items="getRankings" sort-by="points" sort-desc
                                class="elevation-1" mobile-breakpoint="0">

                                <template v-slot:item.index="{ index }">
                                    <b>{{ index + 1 }}</b>
                                </template>

                                <template v-slot:item.score="{ item }">
                                    <b>{{ item.score }}</b>
                                </template>

                                <template v-slot:item.positionChange="{ item }">
                                    <v-chip :class="changeColor(
                                        item.positionChange.toString()
                                    )
                                        " small label class="mr-2 px-2 secondary">
                                        <v-icon class="white--text">
                                            {{ arrowDisplay(item.positionChange.toString()) }}
                                        </v-icon>
                                        {{ positionText(item.positionChange.toString()) }}
                                    </v-chip>
                                </template>

                                <template v-slot:item.team="{ item }">
                                    <span style="display: flex; cursor: pointer;"
                                        @click="gotToTeamHonours(item.team.id)">
                                        <v-img :src="`/assets/gaa/teams/icons/${item.team.code}.WEBP`" contain
                                            max-width="30" class="mr-2 img_border"></v-img>
                                        {{ item.team.name }}
                                    </span>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js';
import StatsCard from '@/components/card/statsCard/StatsCard'

export default {
    name: 'RankingsTable',
    components: {
        StatsCard
    },
    async mounted() {
        if (this.getRankings.length > 0) {
            this.loading = false;
        } else {
            await this.fetchRankings(this.$router.currentRoute.meta.sport.toUpperCase());
        }
        this.populateQueryParams();
    },
    data() {
        return {
            loading: true,
            overallTeamSelections: [],
            overallWinnerYearToFilter: 'All',
            overallTotalsYearFromFilter: 'All',
            overallTotalsYearToFilter: 'All',
            overallTableHeaders: [
                {
                    text: '',
                    sortable: true,
                    value: 'position'
                },
                {
                    text: 'Team',
                    sortable: false,
                    value: 'team'
                },
                { text: 'Weeks at Position', value: 'weeksInPosition', sortable: true },
                { text: 'Score Change', value: 'scoreDelta', sortable: true },
                { text: 'Position Change', value: 'positionChange', sortable: true },
                { text: 'Score', value: 'score', sortable: true }

            ],
        }
    },
    computed: {
        ...mapGetters(['getWinningResults', 'getWinningTeamsHurling', 'getRankings']),
        activeHeaders() {
            if (this.$vuetify.breakpoint.smAndUp) {
                return this.overallTableHeaders
            } else {
                return this.overallTableHeaders.filter(it => it.value != 'index' && it.value != 'provincialRunnerUp')
            }
        },
        overallWinnerYearToFilters() {
            let years = ['All'];
            years.push(...this.getRankings.map(it => it.year).sort((a, b) => b - a));
            return years;
        },
        overallWinnerYearFromFilters() {
            let years = ['All'];
            years.push(...this.getRankings.map(it => it.year).sort((a, b) => a - b));
            return years;
        },

    },
    methods: {
        ...mapActions(['fetchRankings', 'updateOverallTeamSelections', 'updateOverallTotalsYearFromFilter', 'updateOverallTotalsYearToFilter']),
        overallTeamSelectionsChanged() {
            this.updateOverallTeamSelections(this.overallTeamSelections);
            if (this.overallTeamSelections.length == 1) {
                this.$router.push({
                    path: this.$route.path, // Keep the same path
                    query: {
                        ...this.$route.query,
                        team: this.overallTeamSelections[0].id
                    }
                });
            } else {
                this.$router.push({
                    path: this.$route.path, // Keep the same path
                    query: {
                        ...this.$route.query,
                        team: null
                    }
                });
            }
        },
        overallTotalsYearFromFilterChanged() {
            this.updateOverallTotalsYearFromFilter(this.overallTotalsYearFromFilter);
            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    to: this.overallTotalsYearFromFilter
                }
            });
        },
        overallTotalsYearToFilterChanged() {
            this.updateOverallTotalsYearToFilter(this.overallTotalsYearToFilter);
            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    to: this.overallTotalsYearToFilter
                }
            });
        },
        gotToTeamHonours(teamId) {
            this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/statistics/honours/4/${teamId}`)
        },
        arrowDisplay(change) {
            if (!this.ifValueExists(change)) { return '' };
            if (change.indexOf("-") > -1) {
                return 'mdi-triangle-small-up'
            } else if (change == "0") {
                return 'mdi-minus'
            } else {
                return 'mdi-triangle-small-down'
            }
        },
        positionText(change) {
            if (!this.ifValueExists(change)) { return '' };
            if (change.indexOf("-") > -1) {
                return change.substring(1);
            } else if (change == '0') {
                return '';
            } else {
                return '-' + change
            }
        },
        changeColor(movement) {
            if (!this.ifValueExists(movement)) { return '' };
            if (movement > 0) {
                return 'danger white--text'
            } else if (movement == '0') {
                return 'primary white--text'
            } else {
                return 'success white--text'
            }
        },
        ifValueExists(value) {
            if (value !== null && value !== undefined) {
                return true;
            } else {
                return false;
            }
        },
        populateQueryParams() {
            if (this.$router.currentRoute.query.team) {
                this.overallTeamSelections.push(this.getWinningTeamsHurling.find(it => it && it.id == this.$router.currentRoute.query.team));
                this.overallTeamSelectionsChanged();
            }
            if (this.$router.currentRoute.query.from) {
                this.overallTotalsYearFromFilter = this.$router.currentRoute.query.from;
                this.overallTotalsYearFromFilterChanged();
            }
            if (this.$router.currentRoute.query.to) {
                this.overallTotalsYearToFilter = this.$router.currentRoute.query.to;
                this.overallTotalsYearToFilterChanged();
            }
        }
    },
    watch: {
        getRankings(val) {
            if (val.length > 0) {
                this.loading = false;
            }
        }
    }
}
</script>

<style></style>